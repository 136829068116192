import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import Checkout from '../../pages/Checkout'
import Trial from '../../pages/Trial'

function App() {
  return (
    <Switch>
      <Route exact path='/' component={Checkout} />
      <Route exact path='/trial' component={Trial} />
      <Redirect exact from='/criar-conta' to='/' />
    </Switch>
  )
}

export default App
