import { useEffect, useRef } from 'react'
import { withRouter } from 'react-router-dom'
import { Button, Col, Grid, Row } from 'rsuite'

const steps = [
  {
    id: 1,
    step: 'Verifique agora o seu Whatsapp!',
    message: 'Vamos confirmar suas informações por lá agora.',
  },
  {
    id: 2,
    step: '1º acesso ao sistema será em uma call de 15 minutos',
    message:
      'A configuração da sua conta é feita em tempo real com a nossa equipe. <br/> <br/> Não se preocupe! É rapidinho.',
  },
  {
    id: 3,
    step: 'Pronto: Acesse sempre que quiser',
    message:
      'Aproveite nosso teste e experimente uma prática jurídica orientada a dados.',
  },
]

const Trial = () => {
  const gridRef = useRef(null)

  useEffect(() => {
    gridRef.current?.scrollIntoView({ behavior: 'smooth' })
  }, [])

  return (
    <Grid ref={gridRef} fluid className='grid-container'>
      <div className='grid-container_header'>
        <img src='/logo.png' />
        <Button
          className='logout-button'
          onClick={() => window.open('https://turivius.com', '_self')}
        >
          Ir para o site
        </Button>
      </div>

      <Row style={{ marginTop: 70 }}>
        <div className='grid-container_title-container'>
          <h2 className='title-top'>Estamos com tudo (quase) pronto!</h2>
          <h2 className='title-bottom'>Os próximos passos são simples</h2>
        </div>
      </Row>

      <Row className='grid-container_steps-container'>
        {steps.map((step) => (
          <Col key={step.id} xs={24} sm={24} md={24} lg={24}>
            <div className='grid-container_card-step'>
              <section className='section-header'>
                <div className='section-header_number-section'>
                  <div className='number'>{step.id}</div>
                </div>
                <div className='section-header_step-section'>
                  <label className='step'>{step.step}</label>
                </div>
              </section>
              <section className='section-body'>
                <p
                  className='message'
                  dangerouslySetInnerHTML={{ __html: step.message }}
                />
              </section>
            </div>
          </Col>
        ))}
      </Row>

      <Row style={{ marginTop: 90 }}>
        <div className='grid-container_footer-container'>
          <h5>
            Por que você precisa entrar em call para liberar seu teste gratuito?
          </h5>
          <p>
            Nós sabemos que Jurimetria ainda é novidade para o setor jurídico.
            Aqui na Turivius, a gente percebeu que muitos advogados entendem a
            importância da Jurimetria e querem introduzi-la no seu dia a dia,
            mas não sabem como fazer isso na prática. Por isso, nós decidimos
            ter participação ativa nessa facilitação. Formamos um time de
            especialistas que estão prontos para configurar sua conta junto com
            você, explorar a ferramenta junto contigo e definir como você pode
            usar a jurimetria na sua rotina.
          </p>
        </div>
      </Row>
    </Grid>
  )
}
export default withRouter(Trial)
