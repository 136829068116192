import React from 'react'
import { withRouter } from 'react-router-dom'
import CreateAccount from '../components/CreateAccount'
import { Container, Content, Row, Col } from 'rsuite'
import { About } from '../components/About'

const Checkout = () => {
  return (
    <Container className='checkout'>
      <Content className='container'>
        <Row className='account-container'>
          <Col className='section-create'>
            <CreateAccount />
          </Col>
          <Col className='section-about'>
            <About />
          </Col>
        </Row>
      </Content>
    </Container>
  )
}
export default withRouter(Checkout)
