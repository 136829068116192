import React from 'react'
import CreateAccountForm from './CreateAccountForm'

const CreateAccount = () => {
  return (
    <div className='create-account'>
      <CreateAccountForm />
    </div>
  )
}
export default CreateAccount
