import React from 'react'
import { Row, Col } from 'rsuite'

export const WhyTurivius = () => {
  return (
    <div className='why-turivius'>
      <Col className='why-turivius_card'>
        <svg
          width='20'
          height='20'
          viewBox='0 0 20 20'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M11.667 4.16666H16.667'
            stroke='#53B1FD'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M11.667 6.66669H14.167'
            stroke='#53B1FD'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M17.5003 9.58332C17.5003 13.9583 13.9587 17.5 9.58366 17.5C5.20866 17.5 1.66699 13.9583 1.66699 9.58332C1.66699 5.20832 5.20866 1.66666 9.58366 1.66666'
            stroke='#53B1FD'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M18.3337 18.3334L16.667 16.6667'
            stroke='#53B1FD'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
        <p className='card-text'>Automatize a pesquisa jurisprudencial</p>
      </Col>
      <Col className='why-turivius_card'>
        <svg
          width='21'
          height='20'
          viewBox='0 0 21 20'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M12.7946 1.66666H7.35455C4.19801 1.66666 3.95455 4.48332 5.65875 6.01666L14.4904 13.9833C16.1946 15.5167 15.9511 18.3333 12.7946 18.3333H7.35455C4.19801 18.3333 3.95455 15.5167 5.65875 13.9833L14.4904 6.01666C16.1946 4.48332 15.9511 1.66666 12.7946 1.66666Z'
            stroke='#53B1FD'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
        <p className='card-text'>
          Analise a tendência jurisprudencial dos Tribunais
        </p>
      </Col>
      <Col className='why-turivius_card'>
        <svg
          width='20'
          height='20'
          viewBox='0 0 20 20'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M12.5003 18.9584H7.50033C2.97533 18.9584 1.04199 17.025 1.04199 12.5V7.50002C1.04199 2.97502 2.97533 1.04169 7.50033 1.04169H12.5003C17.0253 1.04169 18.9587 2.97502 18.9587 7.50002V12.5C18.9587 17.025 17.0253 18.9584 12.5003 18.9584ZM7.50033 2.29169C3.65866 2.29169 2.29199 3.65835 2.29199 7.50002V12.5C2.29199 16.3417 3.65866 17.7084 7.50033 17.7084H12.5003C16.342 17.7084 17.7087 16.3417 17.7087 12.5V7.50002C17.7087 3.65835 16.342 2.29169 12.5003 2.29169H7.50033Z'
            fill='#53B1FD'
          />
          <path
            d='M12.9167 16.0416C11.65 16.0416 10.625 15.0166 10.625 13.75V6.24998C10.625 4.98331 11.65 3.95831 12.9167 3.95831C14.1833 3.95831 15.2083 4.98331 15.2083 6.24998V13.75C15.2083 15.0166 14.1833 16.0416 12.9167 16.0416ZM12.9167 5.20831C12.3417 5.20831 11.875 5.67498 11.875 6.24998V13.75C11.875 14.325 12.3417 14.7916 12.9167 14.7916C13.4917 14.7916 13.9583 14.325 13.9583 13.75V6.24998C13.9583 5.67498 13.4917 5.20831 12.9167 5.20831Z'
            fill='#53B1FD'
          />
          <path
            d='M7.08366 16.0417C5.81699 16.0417 4.79199 15.0167 4.79199 13.75V10.8334C4.79199 9.56669 5.81699 8.54169 7.08366 8.54169C8.35033 8.54169 9.37533 9.56669 9.37533 10.8334V13.75C9.37533 15.0167 8.35033 16.0417 7.08366 16.0417ZM7.08366 9.79169C6.50866 9.79169 6.04199 10.2584 6.04199 10.8334V13.75C6.04199 14.325 6.50866 14.7917 7.08366 14.7917C7.65866 14.7917 8.12533 14.325 8.12533 13.75V10.8334C8.12533 10.2584 7.65866 9.79169 7.08366 9.79169Z'
            fill='#53B1FD'
          />
        </svg>
        <p className='card-text'>
          Pesquise todo o Judiciário e Órgãos Administrativos
        </p>
      </Col>
      <Col className='why-turivius_card'>
        <svg
          width='21'
          height='20'
          viewBox='0 0 21 20'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <g id='people'>
            <g id='vuesax/linear/people'>
              <g id='people_2'>
                <g id='Group'>
                  <path
                    id='Vector'
                    d='M15.1116 5.96669C15.0612 5.95836 15.0025 5.95836 14.9521 5.96669C13.7936 5.92502 12.8701 4.98336 12.8701 3.81669C12.8701 2.62502 13.8355 1.66669 15.036 1.66669C16.2365 1.66669 17.202 2.63336 17.202 3.81669C17.1936 4.98336 16.2701 5.92502 15.1116 5.96669Z'
                    stroke='#53B1FD'
                    strokeWidth='1.5'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                  <path
                    id='Vector_2'
                    d='M14.2469 12.0334C15.397 12.225 16.6646 12.025 17.5545 11.4334C18.7382 10.65 18.7382 9.3667 17.5545 8.58337C16.6563 7.9917 15.3718 7.79169 14.2217 7.99169'
                    stroke='#53B1FD'
                    strokeWidth='1.5'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </g>
                <g id='Group_2'>
                  <path
                    id='Vector_3'
                    d='M5.01127 5.96669C5.06164 5.95836 5.1204 5.95836 5.17078 5.96669C6.32929 5.92502 7.25275 4.98336 7.25275 3.81669C7.25275 2.62502 6.28732 1.66669 5.08683 1.66669C3.88633 1.66669 2.9209 2.63336 2.9209 3.81669C2.92929 4.98336 3.85275 5.92502 5.01127 5.96669Z'
                    stroke='#53B1FD'
                    strokeWidth='1.5'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                  <path
                    id='Vector_4'
                    d='M5.8761 12.0334C4.72597 12.225 3.45832 12.025 2.56844 11.4334C1.38474 10.65 1.38474 9.3667 2.56844 8.58337C3.46671 7.9917 4.75116 7.79169 5.90128 7.99169'
                    stroke='#53B1FD'
                    strokeWidth='1.5'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </g>
                <g id='Group_3'>
                  <path
                    id='Vector_5'
                    d='M10.0735 12.1917C10.0231 12.1833 9.96438 12.1833 9.91401 12.1917C8.75549 12.15 7.83203 11.2083 7.83203 10.0417C7.83203 8.85 8.79746 7.89166 9.99796 7.89166C11.1984 7.89166 12.1639 8.85833 12.1639 10.0417C12.1555 11.2083 11.232 12.1583 10.0735 12.1917Z'
                    stroke='#53B1FD'
                    strokeWidth='1.5'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                  <path
                    id='Vector_6'
                    d='M7.63094 14.8167C6.44724 15.6 6.44724 16.8833 7.63094 17.6667C8.97415 18.5583 11.1737 18.5583 12.5169 17.6667C13.7006 16.8833 13.7006 15.6 12.5169 14.8167C11.1821 13.9333 8.97415 13.9333 7.63094 14.8167Z'
                    stroke='#53B1FD'
                    strokeWidth='1.5'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </g>
              </g>
            </g>
          </g>
        </svg>
        <p className='card-text'>
          Filtre precedentes por resultados e salve em coleções
        </p>
      </Col>
    </div>
  )
}
