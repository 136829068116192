export const MICROSERVICES = {
  webservice: process.env.REACT_APP_WEBSERVICE,
  profiles: process.env.REACT_APP_PROFILES,
  payments: process.env.REACT_APP_PAYMENTS,
  cards: process.env.REACT_APP_CARDS,
  reports: process.env.REACT_APP_REPORTS,
  segmentation: process.env.REACT_APP_SEGMENTATION,
}

function parseJSON(response) {
  if (response.ok) {
    return new Promise((resolve) =>
      response.json().then((json) =>
        resolve({
          status: response.status,
          ok: response.ok,
          json,
        })
      )
    )
  }
  return new Promise((resolve, reject) =>
    response.json().then((json) =>
      reject({
        status: response.status,
        message: json.errors
          ? json.errors.join(', ')
          : json.message
            ? json.message
            : response.ok,
      })
    )
  )
}

function getQueryParams(data, type) {
  if (type === 'GET' && data) {
    return `?${Object.keys(data)
      .map((key) => {
        if (Array.isArray(data[key])) {
          return data[key].map((elem) => `${key}[]=${elem}`).join('&')
        } else {
          return `${key}=${data[key]}`
        }
      })
      .join('&')}`
  } else {
    return ''
  }
}

function getBody(data, type, contentType) {
  if (type === 'GET') {
    return undefined
  } else if (
    contentType === 'application/x-www-form-urlencoded; charset=UTF-8' &&
    data
  ) {
    let formData = []
    Object.keys(data).forEach((key) => {
      formData.push(`${key}=${data[key]}`)
    })
    return formData.join('&')
  } else {
    return data ? JSON.stringify(data) : data
  }
}

function call({ type, action, microservice, auth, data, format, contentType }) {
  let headers = new Headers()
  if (auth) {
    headers.append('Authorization', `Token ${localStorage.getItem('token')}`)
  }
  headers.append('Content-Type', contentType)

  let init = {
    method: type,
    headers: headers,
    mode: 'cors',
    cache: 'default',
    body: getBody(data, type, contentType),
  }
  return new Promise((resolve, reject) => {
    fetch(`${microservice}${action}${getQueryParams(data, type)}`, init)
      .then(parseJSON)
      .then((response) => {
        if (response.ok) {
          return resolve(response.json)
        }
        console.error(response)
        return reject(response)
      })
      .catch((error) =>
        reject({
          status: error.status,
          message: error.message,
        })
      )
  })
}

export function request({
  type = 'GET',
  action,
  microservice,
  auth,
  data = undefined,
  format = 'json',
  contentType = 'application/json',
}) {
  switch (microservice) {
    case 'profiles':
    case 'payments':
    case 'cards':
    case 'reports':
    case 'segmentation':
      return call({
        type,
        action,
        microservice: MICROSERVICES[microservice],
        auth,
        data,
        format,
        contentType,
      })
    default:
      return call({
        type,
        action,
        microservice: MICROSERVICES.webservice,
        auth,
        data,
        format,
        contentType,
      })
  }
}
