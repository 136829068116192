import clients from './clients.json'
import { Col, Row } from 'rsuite'

export const WhoUse = () => {
  return (
    <Row className='who'>
      <h1 className='who-title'>
        Tenha uma <strong>atuação data-driven</strong> como outras{' '}
        <strong>grandes empresas e escritórios</strong>:
      </h1>
      <Col className='who-images'>
        {clients.map(({ image, alt }) => (
          <img key={alt} src={image} alt={alt} />
        ))}
      </Col>
    </Row>
  )
}
