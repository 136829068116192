import { Demonstration } from '../Demonstration'
import { WhoUse } from '../WhoUse'
import { WhyTurivius } from '../WhyTurivius'

export const About = () => {
  return (
    <section className='column'>
      <div className='column-about'>
        <h3 className='subtitle'>
          <strong>Reduza em 60% o tempo gasto </strong>com pesquisa
          jurisprudencial e{' '}
          <strong>utilize Jurimetria para tomar decisões</strong>.
        </h3>

        <div className='why-use'>
          <WhyTurivius />
        </div>

        <WhoUse />
        <Demonstration />
      </div>
    </section>
  )
}
