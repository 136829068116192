import axios from 'axios'
import Cookies from 'js-cookie'

export const submit_hubspot_form = async (
  company,
  profile,
  companyEmployees
) => {
  const portalId = '25979713'
  const formGuid = 'a87beb60-1c88-430b-be56-e50cca3deb20'
  const config = {
    // important!
    headers: {
      'Content-Type': 'application/json',
    },
  }

  // removing parenthesis from cellphone
  // let cellphone = company.cellphone.replace(/\(|\)/g, "").replace(/-/, "").replace(/ /, '').replace(/ /, '');
  let cellphone = company.cellphone.replace(/[()\s-]/g, '')

  // transform data to hubspot api format
  let law_area = profile.law_area.join(';')

  const response = await axios.post(
    `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`,
    {
      portalId,
      formGuid,
      fields: [
        {
          name: 'firstname',
          value: profile.name,
        },
        {
          name: 'email',
          value: profile.email,
        },
        {
          name: 'phone',
          value: cellphone,
        },
        {
          name: 'company',
          value: company.name,
        },
        {
          name: 'quantos__advogados_ha_na_sua_empresa_escritorio_',
          value: companyEmployees,
        },
        {
          name: 'areas_de_atuacao',
          value: law_area,
        },
      ],
      context: {
        hutk: Cookies.get('hubspotutk'), // include this parameter and set it to the hubspotutk cookie value to enable cookie tracking on your submission
        pageUri: window.location.href,
        pageName: 'Self Checkout',
      },
    },
    config
  )
  return response
}
