import { ArrayType, SchemaModel, StringType } from 'schema-typed'

const schema = SchemaModel({
  name: StringType().isRequired('Preencha este campo.'),
  email: StringType()
    .isEmail('Por favor, informe um e-mail válido')
    .isRequired('Preencha este campo.'),
  password: StringType()
    .minLength(8, 'Sua senha deve conter no mínimo 8 caracteres')
    .isRequired('Preencha este campo.'),
  cellphone: StringType()
    .addRule((value) => {
      return !value.includes('_')
    }, 'Informe um número de celular válido')
    .isRequired('Preencha este campo.'),
  companyEmployees: StringType()
    .minLength(1, 'Escolha ao menos uma opção')
    .isRequired('Campo obrigatório'),
  law_area: ArrayType()
    .minLength(1, 'Escolha ao menos uma opção')
    .isRequired('Campo obrigatório'),
})

export default schema
